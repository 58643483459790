import React from 'react'

const Loading = () => {
    return (
        <div id='preloader-active'>
            <div className='preloader d-flex align-items-center justify-content-center'>
                <div className='preloader-inner position-relative'>
                <div className='text-center'><img src={require('../assets/imgs/template/loading.gif')} alt='Fan360' /></div>
                </div>
            </div>
        </div>
    )
}

export default Loading