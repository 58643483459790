import axios from 'axios'
import { history } from './history' 
import { constants } from './constants'

const api = axios.create({
    baseURL: process.env.REACT_APP_API
})

api.interceptors.request.use(function(config){
    if(!config.url.endsWith('login')){
        const token = localStorage.getItem(constants.ACCESS_TOKEN)

       // if(!token) {
       //     history.push('/login')
       // }

        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

api.interceptors.response.use((response) => {
    return response
}, (error) => {
    console.log(error)
    if(error.response === undefined || error.response.status === 401) {
        const requestConfig = error.config
        //history.push('/login')
        return axios(requestConfig)
    }
    return Promise.reject(error)
})

export default api