import React, { createContext, useContext, useEffect } from 'react'
import { constants } from './services/constants'
import useAuth from './_hooks/useAuth'

const StoreContext = createContext()

export const useStore = () => {
    const [store, setStore] = useContext(StoreContext)
    return [store, setStore]
}

export const StoreProvider = ({ children }) => {
    const auth = useAuth()

    const rehydrate = async () => {
        const username = await localStorage.getItem(constants.USERNAME)
        const autenticado = await localStorage.getItem(constants.AUTENTICADO)

        auth.setLoginData({ usuario: username || null})
        auth.setAuthed(autenticado || false)
        
        auth.checkUser()

        if(!autenticado) {
            auth.logout()
        }
    }

    useEffect(() => {
        rehydrate()
    }, [])

    return(
        
        <StoreContext.Provider value={[auth]}>
            { children }
        </StoreContext.Provider>
    )
}


