import React, { Suspense } from 'react'
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import Loading from './_components/Loading'
import { history } from './services/history'

const DefaultLayout = React.lazy(() => import('./views/DefaultLayout'))

const Login = React.lazy(() => import('./views/Login'))
const Register = React.lazy(() => import('./views/Register'))
const RegisterConfirm = React.lazy(() => import('./views/Register/RegisterConfirm'))

const App = () => {

  return (
    <HistoryRouter history={history}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route exact path='/login' name='Acesso do usuário' element={<Login />} />
          <Route exact path='/register' name='Registrar usuário' element={<Register />} />
          <Route exact path='/register-confirm' name='Registrar usuário' element={<RegisterConfirm />} />
          <Route path='*' name='Main' element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HistoryRouter>
  )
}

export default App
