import axios from 'axios'

const apiBasic = axios.create({
    baseURL: process.env.REACT_APP_API_LOGIN
})

apiBasic.interceptors.request.use(function(config){
        config.headers.Authorization = `Basic Y29ubmVjdGFzOmFkYW1wcm9qZWN0`
    return config
}, (error) => {
    return Promise.reject(error)
})

apiBasic.interceptors.response.use((response) => {
    return response
}, (error) => {
    if(error.response === undefined || error.response.status === 401) {
        const requestConfig = error.config
        return axios(requestConfig)
    }
    return Promise.reject(error)
})

export default apiBasic