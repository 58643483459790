import { useState } from 'react'
import apiBasic from '../services/apiBasic'
import api from '../services/api'
import { constants } from '../services/constants'

const registerLogin = (data, username) => {
    localStorage.setItem(constants.ACCESS_TOKEN, data.data.access_token)
    localStorage.setItem(constants.REFRESH_TOKEN, data.data.refresh_token)
    localStorage.setItem(constants.USERNAME, username)
    localStorage.setItem(constants.AUTENTICADO, true)
}

const useAuth = () => {
    const [authed, setAuthed] = useState(false)
    const [loginData, setLoginData] = useState({})
    const [loginError, setLoginError] = useState(null)
    const [actionLoading, setActionLoading] = useState(false)
    const [loginSuccess, setLoginSuccess] = useState(false)

    const loginUser = async (dataLogin) => {
        let userInfo = {
            username: dataLogin.usuario,
            password: dataLogin.senha
        }
        setActionLoading(true)

        let formData = new FormData()
        formData.append('username', userInfo.username)
        formData.append('password', userInfo.password)

        setLoginError(null)

        await apiBasic.post('/oauth/token?grant_type=password', formData, { headers: {'Content-type': 'multipart/form-data' }})
                    .then(data => {
                        registerLogin(data, userInfo.username)
                        setLoginData({usuario: userInfo.username})
                        setAuthed(true)
                        setActionLoading(false)
                        setLoginSuccess(true)
                        return data
                    })
                    .catch(error => {
                        logout()
                        setLoginError(error.response.data.error_description)
                        setAuthed(false)
                        setActionLoading(false)
                        setLoginSuccess(false)
                        return error.response
                    })
    }

    const logout = async () => {
        setActionLoading(true)
        localStorage.removeItem(constants.USERNAME)
        localStorage.removeItem(constants.ACCESS_TOKEN)
        localStorage.removeItem(constants.REFRESH_TOKEN)
        localStorage.removeItem(constants.AUTENTICADO)
        setAuthed(false)
        setLoginSuccess(false)
        setActionLoading(false)
    }

    const checkUser = async () => {
        await api.get('/users/me/')
            .then(data => {
                return true
            })
            .catch(error => {
                logout()
                setAuthed(false)
                setActionLoading(false)
                setLoginSuccess(false)
                return false
            })
    }

    return { authed, loginUser, logout, checkUser, loginData, loginError, actionLoading, loginSuccess, setLoginData, setAuthed }
}

export default useAuth